import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { FormControl, FormGroup } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import type { TPEEntityType } from '@pi/pi-common/src/ui-dtos/third-party-entity.dtos';
import { ChipModule } from 'primeng/chip';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';

import type { TpeForm } from './tpe-form.interface';
import type { DropdownOption } from '../../../lib/interfaces/dropdown-option.dtos';

@Component({
    selector: 'pay-create-tpe-form',
    imports: [ReactiveFormsModule, DropdownModule, ChipModule, InputTextModule],
    templateUrl: './create-tpe-form.component.html',
    styleUrl: './create-tpe-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateTpeFormComponent {
    @Input() public tpeForm: FormGroup<TpeForm> | null = null;
    @Input() public entityTypeOptions: DropdownOption<TPEEntityType>[] = [];

    /**
     * Selects the type of Third Party Entity to create.
     * @param value The selected Third Party Entity type.
     * @param formControl The form control for the Type field.
     */
    public selectEntityType(value: TPEEntityType, formControl: FormControl<TPEEntityType>) {
        formControl.setValue(value);
    }
}
