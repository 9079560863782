import { Component, inject } from '@angular/core';
import type { OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import type { ToastMessageOptions } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { FluidModule } from 'primeng/fluid';
import { ToastModule } from 'primeng/toast';

import { GlobalMessageLocation } from './lib/interfaces/global-message-enums';
import { GlobalMessagesService } from './lib/services/global-messages.service';
import { ThemeService } from './lib/services/theme.service';
import { UserActivityService } from './lib/services/user-activity.service';
import { PRIVACY_POLICY_PATH, TERMS_AND_CONDITIONS_PATH } from './shared/constants/route-paths';
import { NavigationHelpers } from './shared/services/navigation-helpers.service';

@Component({
    selector: 'pay-app-root',
    templateUrl: './app.component.html',
    imports: [ButtonModule, RouterOutlet, ToastModule, FluidModule]
})
export class AppComponent implements OnInit {
    public static printablePagePaths = [TERMS_AND_CONDITIONS_PATH, PRIVACY_POLICY_PATH];

    private readonly userActivityService = inject(UserActivityService);
    private readonly messageService = inject(MessageService);
    private readonly globalMessagesService = inject(GlobalMessagesService);
    private readonly navigationHelpers = inject(NavigationHelpers);

    public constructor() {
        const themeService = inject(ThemeService);
        void themeService.init();
    }

    public get locationPath() {
        return window.location.pathname;
    }

    public isStaticPage(path = this.locationPath): boolean {
        for (const printablePath of AppComponent.printablePagePaths) {
            if (path.includes(printablePath)) {
                return true;
            }
        }
        return false;
    }

    public onConfirm() {
        this.messageService.clear(GlobalMessageLocation.CONFIRM);
        this.userActivityService.reset();
    }

    public onReject() {
        this.messageService.clear(GlobalMessageLocation.CONFIRM);
        void this.navigationHelpers.navigateToLogoutWithRedirect();
    }

    public ngOnInit(): void {
        this.globalMessagesService.messageBehaviorSubject.subscribe((message: ToastMessageOptions | null) => {
            if (message) {
                if (message.data?.clear && message.key) {
                    this.messageService.clear(message.key);
                }
                this.messageService.add(message);
            } else {
                this.onClearAllMessages();
            }
        });
    }

    private onClearAllMessages(): void {
        this.messageService.clear(GlobalMessageLocation.MAIN);
        this.messageService.clear(GlobalMessageLocation.CENTER);
        this.messageService.clear(GlobalMessageLocation.CONFIRM);
    }
}
