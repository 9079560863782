import { ChangeDetectionStrategy, Component, ViewChild, computed, inject, signal } from '@angular/core';
import type { TPEWithPlanSponsors } from '@pi/pi-common/src/ui-dtos/third-party-entity.dtos';
import { DateTime } from 'luxon';
import { ButtonModule } from 'primeng/button';

import { GlobalMessageLocation } from '../../lib/interfaces/global-message-enums';
import { GlobalMessagesService } from '../../lib/services/global-messages.service';
import { ThirdPartyEntityService } from '../../lib/services/third-party-entity.service';
import { TableDataSource } from '../../shared/classes/table-data-source';
import { TableDatetimeColumnComponent } from '../../shared/components/table-datetime-column/table-datetime-column.component';
import { TableQueryParamDirective } from '../../shared/components/table-query-param/table-query-param.directive';
import { TableSearchInputComponent } from '../../shared/components/table-search-input/table-search-input.component';
import { hasMessage } from '../../shared/functions/has-message';
import { PayTableViewModule } from '../../shared/modules/pay-table-view.module';
import { PayTableModule } from '../../shared/modules/pay-table.module';
import { STORAGE_KEY } from '../../shared/services/storage.service';
import { TABLE_QUERY_PARAM_HOST_TOKEN } from '../../shared/tokens/table-query-param-host-token';
import type { HasTableDataSource } from '../../shared/types/has-table-data-source';
import { CreateTpeModalComponent } from '../../user-management/components/create-tpe-modal/create-tpe-modal.component';

type SortableTableColumns = 'display_name';

@Component({
    selector: 'pay-tpe-table',
    imports: [
        CreateTpeModalComponent,
        TableSearchInputComponent,
        TableDatetimeColumnComponent,
        ButtonModule,
        PayTableModule,
        PayTableViewModule
    ],
    hostDirectives: [TableQueryParamDirective],
    providers: [{ provide: TABLE_QUERY_PARAM_HOST_TOKEN, useExisting: TpeTableComponent }],
    templateUrl: './tpe-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TpeTableComponent implements HasTableDataSource<TPEWithPlanSponsors, SortableTableColumns> {
    public modalOpen = false;
    public readonly tableDataSource = new TableDataSource<TPEWithPlanSponsors, SortableTableColumns>(
        async ({ limit, offset, search, sortField, sortOrder, setTotalSize }) => {
            let thirdPartyEntities: readonly TPEWithPlanSponsors[] = [];
            this.loading.set(true);

            try {
                const response = await this.thirdPartyEntityService.getPaginatedEntities(
                    limit,
                    offset,
                    search,
                    sortField,
                    sortOrder,
                    'THIRD_PARTY_ADMINISTRATOR'
                );
                if (response) {
                    thirdPartyEntities = response.data;
                    setTotalSize(response.meta.fullCount);
                }
            } catch (e: unknown) {
                if (!hasMessage(e)) {
                    throw e;
                }

                this.globalMessagesService.error({
                    key: GlobalMessageLocation.CENTER,
                    summary: 'Error loading third party entities',
                    detail: e.message || 'Something went wrong.'
                });
            } finally {
                this.loading.set(false);
            }

            return thirdPartyEntities;
        },
        [],
        {},
        STORAGE_KEY.TPE_TABLE
    );
    @ViewChild('createTpeModal', { static: true })
    protected readonly createTpeModal!: CreateTpeModalComponent;
    protected readonly loading = signal(true);
    protected readonly createdDateFormat = DateTime.DATETIME_MED;
    protected readonly visibleColumns = computed(() => {
        return ['display_name', 'identifier', 'created_at'];
    });

    private readonly thirdPartyEntityService = inject(ThirdPartyEntityService);
    private readonly globalMessagesService = inject(GlobalMessagesService);
    /**
     * Opens the Third Party Entity modal.
     */
    public openTpeModal() {
        this.modalOpen = true;
    }

    /**
     * Closes the Third Party Entity modal.
     */
    public closeTpeModal(reloadTable: boolean = false) {
        this.modalOpen = false;
        if (reloadTable) {
            void this.tableDataSource.forceUpdate();
        }
    }
}
