<div class="modal-close-button-wrapper">
    <button
        pButton
        id="piModalCloseButton"
        type="button"
        icon="pi pi-times"
        class="modal-close-button p-button-rounded p-button-text p-button-plain"
        styleClass="p-0"
        [disabled]="disabled"
        (click)="handleCloseClick($event)"
    ></button>
    <p-confirmPopup [key]="popupKey ?? undefined" />
</div>
