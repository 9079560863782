import { provideHttpClient, withFetch, withInterceptors, withXsrfConfiguration } from '@angular/common/http';
import { ErrorHandler, enableProdMode, importProvidersFrom, inject, provideAppInitializer } from '@angular/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { NavigationEnd, Router, provideRouter, withComponentInputBinding, withRouterConfig } from '@angular/router';
import { definePreset } from '@primeng/themes';
import Lara from '@primeng/themes/lara';
import * as Sentry from '@sentry/angular';
import { NgxStripeModule } from 'ngx-stripe';
import { ConfirmationService, MessageService } from 'primeng/api';
import { providePrimeNG } from 'primeng/config';
import { DialogService } from 'primeng/dynamicdialog';
import { filter } from 'rxjs';

import packageJson from '../package.json';
import { AppComponent } from './app/app.component';
import { ROUTES } from './app/app.routes';
import { DIVISION_VERIFICATION_CACHE_TEARDOWN } from './app/division/services/division-verification-cache.service';
import { BillingService } from './app/lib/services/billing.service';
import { DataService } from './app/lib/services/data.service';
import { DynamicFormsService } from './app/lib/services/dynamic-forms.service';
import { RECORD_KEEPER_CACHE_TEARDOWN, RecordKeeperService } from './app/lib/services/record-keeper.service';
import { TPAService } from './app/lib/services/tpa.service';
import { authInterceptor } from './app/shared/http-interceptors/auth.interceptor';
import { basePathInterceptor } from './app/shared/http-interceptors/base-path.interceptor';
import { requestHeadersInterceptor } from './app/shared/http-interceptors/request-headers.interceptor';
import { retryInterceptor } from './app/shared/http-interceptors/retry.interceptor';
import { sentryReportingInterceptor } from './app/shared/http-interceptors/sentry-reporting.interceptor';
import { ConcatTitleStrategyProvider } from './app/shared/services/concat-title-strategy.service';
import { DIVISION_CACHE_TEARDOWN } from './app/shared/services/division-cache.service';
import { PLAN_SPONSOR_CACHE_TEARDOWN } from './app/shared/services/plan-sponsor-cache.service';
import { ROLE_CACHE_TEARDOWN } from './app/shared/services/role-cache.service';
import { StorageService } from './app/shared/services/storage.service';
import { THIRD_PARTY_ENTITY_CACHE_TEARDOWN } from './app/shared/services/third-party-entity-cache.service';
import { environment } from './environments/environment';

declare global {
    interface Window {
        __APP_META__: {
            dependencies?: Record<string, string>;
            devDependencies?: Record<string, string>;
            deployedAt: number;
            gitCommit: string;
            gitCommitShort: string;
            gitRef: string;
        };
    }
}

window.__APP_META__ = {
    dependencies: packageJson.dependencies,
    devDependencies: packageJson.devDependencies,
    deployedAt: Number(environment.deployedAt),
    gitCommit: environment.gitCommit,
    gitCommitShort: environment.gitCommitShort,
    gitRef: environment.gitRef
};

if (environment.production) {
    delete window.__APP_META__.dependencies;
    delete window.__APP_META__.devDependencies;

    Sentry.init({
        environment: environment.sentryEnvironmentName ?? 'unknown',
        dsn: 'https://32cd1db89d544d499fdf81dbe5e5bef6@o1064254.ingest.sentry.io/6054981',
        integrations: [Sentry.browserTracingIntegration()],
        tracePropagationTargets: ['payrollintegrationsapp.com', 'payrollintegrationsdemo.com'],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.5
    });

    enableProdMode();
}

const MyPreset = definePreset(Lara, {
    components: {
        breadcrumb: {
            css: () => `
.p-breadcrumb-item-link:hover {
    text-decoration: underline;
}
`
        },
        card: {
            extend: {
                border: {
                    color: '{content.border.color}'
                }
            },
            colorScheme: {
                light: {
                    root: {
                        shadow: 'none'
                    }
                },
                dark: {
                    root: {
                        shadow: 'none'
                    }
                }
            },
            css: ({ dt }: { dt: any }) => `
                .p-card {
                  border: 1px solid ${dt('card.border.color')};
                }
            `
        },
        tag: {
            // Reset the tag's icon size with a more specific style
            css: ({ dt }: { dt: any }) => `
                .p-tag .p-tag-icon {
                  font-size: ${dt('tag.icon.size')};
                }
            `
        },
        confirmdialog: {
            // Reset the dialog's icon size with a more specific style
            css: ({ dt }: { dt: any }) => `
                .p-confirmdialog .p-confirmdialog-icon {
                  font-size: ${dt('confirmdialog.icon.size')};
                }
            `
        }
    },
    primitive: {
        blue: {
            50: '#f4f8fe',
            100: '#cadef9',
            200: '#a0c5f5',
            300: '#75abf0',
            400: '#4b91ec',
            500: '#2177e7',
            600: '#1c65c4',
            700: '#1753a2',
            800: '#12417f',
            900: '#0d305c',
            950: '#081e3a'
        },
        green: {
            50: '#f2f9f6',
            100: '#c2e1d5',
            200: '#91cab4',
            300: '#61b393',
            400: '#309b71',
            500: '#008450',
            600: '#007044',
            700: '#005c38',
            800: '#00492c',
            900: '#003520',
            950: '#002114'
        },
        red: {
            50: '#fdf5f6',
            100: '#f7ced2',
            200: '#f0a7ae',
            300: '#e9808a',
            400: '#e35967',
            500: '#dc3243',
            600: '#bb2b39',
            700: '#9a232f',
            800: '#791c25',
            900: '#58141b',
            950: '#370d11'
        }
    },
    semantic: {
        primary: {
            50: '#f6f8fa',
            100: '#d3dce7',
            200: '#b0c1d3',
            300: '#8da5c0',
            400: '#6a8aac',
            500: '#476e99',
            600: '#3c5e82',
            700: '#324d6b',
            800: '#273d54',
            900: '#1c2c3d',
            950: '#121c26'
        },
        colorScheme: {
            dark: {
                primary: {
                    50: '#f9fcfd',
                    100: '#e3eef7',
                    200: '#cde1f0',
                    300: '#b6d4ea',
                    400: '#a0c7e3',
                    500: '#8abadd',
                    600: '#759ebc',
                    700: '#61829b',
                    800: '#4c667a',
                    900: '#374a58',
                    950: '#232f37'
                }
            }
        }
    }
});

bootstrapApplication(AppComponent, {
    providers: [
        provideAnimationsAsync(),
        providePrimeNG({
            theme: {
                preset: MyPreset,
                options: {
                    darkModeSelector: '.pi-theme-dark'
                }
            }
        }),
        importProvidersFrom(
            BrowserAnimationsModule,
            BrowserModule,
            NgxStripeModule.forRoot(environment.stripePublishableKey)
        ),
        provideHttpClient(
            withFetch(),
            withXsrfConfiguration({
                cookieName: 'XSRF-TOKEN',
                headerName: 'X-XSRF-TOKEN'
            }),
            withInterceptors([
                basePathInterceptor,
                requestHeadersInterceptor,
                authInterceptor,
                retryInterceptor,
                sentryReportingInterceptor
            ])
        ),
        provideRouter(ROUTES, withRouterConfig({ paramsInheritanceStrategy: 'always' }), withComponentInputBinding()),
        RecordKeeperService,
        TPAService,
        StorageService,
        DataService,
        DialogService,
        ConfirmationService,
        DynamicFormsService,
        MessageService,
        BillingService,
        ConcatTitleStrategyProvider,
        provideAppInitializer(() => {
            const initializerFn = ((router: Router, dialogService: DialogService) => () => {
                router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
                    dialogService.dialogComponentRefMap?.forEach((comp, key) => {
                        key.close(); // Close any open modals when navigating away from a page
                    });
                });
            })(inject(Router), inject(DialogService));
            return initializerFn();
        }),
        // Sentry Providers
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                // These do not play well with our modals
                showDialog: false
            })
        },
        {
            provide: Sentry.TraceService,
            deps: [Router]
        },
        RECORD_KEEPER_CACHE_TEARDOWN,
        PLAN_SPONSOR_CACHE_TEARDOWN,
        DIVISION_CACHE_TEARDOWN,
        DIVISION_VERIFICATION_CACHE_TEARDOWN,
        THIRD_PARTY_ENTITY_CACHE_TEARDOWN,
        ROLE_CACHE_TEARDOWN
    ]
}).catch((err) => console.error(err));
