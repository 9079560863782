import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot } from '@angular/router';
import { createUrlTreeFromSnapshot } from '@angular/router';
import { RecordKeeperConfigList } from '@pi/pi-configuration/src/configuration/record-keepers/list';

import { GlobalMessageLocation } from '../../lib/interfaces/global-message-enums';
import { GlobalMessagesService } from '../../lib/services/global-messages.service';
import { RecordKeeperService } from '../../lib/services/record-keeper.service';

export const supportedRecordKeeperGuard = (route: ActivatedRouteSnapshot) => {
    const globalMessagesService = inject(GlobalMessagesService);
    const recordKeeperService = inject(RecordKeeperService);

    const supportedRecordKeeperParam = String(
        route.params.supportedRecordKeeper ?? route.parent?.params.supportedRecordKeeper
    ).toLowerCase();
    const matchingRecordKeeper = RecordKeeperConfigList.find(
        (recordKeeper) =>
            recordKeeper.identifier.toLowerCase() === supportedRecordKeeperParam ||
            recordKeeper.uiConfig.slugOverride?.toLowerCase() === supportedRecordKeeperParam
    );

    recordKeeperService.updateRecordKeeper(matchingRecordKeeper ?? null);

    if (!matchingRecordKeeper) {
        globalMessagesService.error({
            key: GlobalMessageLocation.CENTER,
            detail: 'Please review the URL you navigated to as the recordkeeper you provided could not be found.'
        });
        return createUrlTreeFromSnapshot(route, ['/']);
    }

    const slugOverride = matchingRecordKeeper.uiConfig.slugOverride?.toLowerCase();
    const identifier = matchingRecordKeeper.identifier.toLowerCase();

    if (
        supportedRecordKeeperParam === identifier &&
        slugOverride !== undefined &&
        supportedRecordKeeperParam !== slugOverride
    ) {
        return createUrlTreeFromSnapshot(route, ['/', slugOverride, 'login']);
    }

    return true;
};
