import { Component, Input, inject, output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';

export type ModalCloseButtonCloseClickType = 'CLOSE' | 'CANCELED';

@Component({
    selector: 'pay-modal-close-button',
    templateUrl: './modal-close-button.component.html',
    styleUrl: './modal-close-button.component.scss',
    imports: [ButtonModule, ConfirmPopupModule]
})
export class ModalCloseButtonComponent {
    @Input() public confirmMessage: string | undefined | null;
    @Input() public disabled = false;
    @Input() public popupKey: string | undefined | null;
    public readonly closeClick = output<ModalCloseButtonCloseClickType>();

    private readonly confirmationService = inject(ConfirmationService);

    public handleCloseClick(event: Event): void {
        if (this.confirmMessage) {
            this.confirmationService.confirm({
                target: event.target || undefined,
                message: this.confirmMessage,
                icon: 'pi pi-exclamation-triangle',
                key: this.popupKey ?? undefined,
                accept: () => {
                    setTimeout(() => {
                        this.closeClick.emit('CLOSE');
                    }, 100);
                },
                reject: () => {
                    setTimeout(() => {
                        this.closeClick.emit('CANCELED');
                    }, 100);
                }
            });
        } else {
            this.closeClick.emit('CLOSE');
        }
    }
}
