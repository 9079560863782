@if (tpeForm !== null) {
    <div [formGroup]="tpeForm">
        <div class="field">
            <label for="tpeTypeDropdown">Entity Type</label>
            <p-dropdown
                formControlName="type"
                id="tpeTypeDropdown"
                [options]="entityTypeOptions"
                (onChange)="selectEntityType($event.value, tpeForm.controls.type)"
            />
        </div>
        <div class="field">
            <label for="tpeDisplayNameInput">Entity Display Name</label>
            <input
                pInputText
                formControlName="display_name"
                id="tpeDisplayNameInput"
                placeholder="Display Name"
                type="text"
            />
        </div>
        <div class="field">
            <label for="tpeIdentifierInput">Entity Identifier</label>
            <input
                pInputText
                aria-describedby="tpeIdentifierHelp"
                formControlName="identifier"
                id="tpeIdentifierInput"
                placeholder="Identifier"
                type="text"
            />
            <small id="tpeIdentifierHelp">Must only contain lowercase letters, numbers, and hyphens.</small>
        </div>
    </div>
}
