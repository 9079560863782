import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject, output } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import type { TPECreate } from '@pi/pi-common/src/ui-dtos/third-party-entity.dtos';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { BehaviorSubject } from 'rxjs';

import { GlobalMessagesService } from '../../../lib/services/global-messages.service';
import { ThirdPartyEntityService } from '../../../lib/services/third-party-entity.service';
import { ModalHeaderComponent } from '../../../shared/components/modal-header/modal-header.component';
import { CreateTpeFormComponent } from '../create-tpe-form/create-tpe-form.component';
import type { TpeForm } from '../create-tpe-form/tpe-form.interface';
import { TpeFormService } from '../create-tpe-form/tpe-form.service';

@Component({
    selector: 'pay-create-tpe-modal',
    imports: [CommonModule, SharedModule, DialogModule, ButtonModule, ModalHeaderComponent, CreateTpeFormComponent],
    templateUrl: './create-tpe-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateTpeModalComponent {
    @Input() public visible = false;
    public readonly modalCloseEvent = output<boolean>();

    public tpeForm = inject(TpeFormService).newForm();
    public entityTypeOptions = inject(TpeFormService).getEntityTypeOptions();

    protected readonly loading = new BehaviorSubject<boolean>(false);

    private readonly tpeFormService = inject(TpeFormService);
    private readonly thirdPartyEntityService = inject(ThirdPartyEntityService);
    private readonly globalMessagesService = inject(GlobalMessagesService);

    /**
     * Creates a Third Party Entity using information provided by the user.
     * @param tpeForm Contains the values from the form.
     */
    public async createThirdPartyEntity(tpeForm: FormGroup<TpeForm>) {
        const entity: TPECreate = {
            type: tpeForm.controls.type.value,
            display_name: tpeForm.controls.display_name.value,
            identifier: tpeForm.controls.identifier.value
        };
        try {
            this.loading.next(true);
            await this.thirdPartyEntityService.createEntity(entity);

            this.globalMessagesService.success({
                summary: 'Success',
                detail: 'Third Party Entity successfully created.'
            });
            this.closeModal(true);
        } catch (error) {
            const detail = this.getErrorMessage(error);
            this.globalMessagesService.error({
                summary: 'Error',
                detail
            });
        } finally {
            this.loading.next(false);
        }
    }

    /**
     * Closes the Third Party Entity modal and resets the form.
     */
    protected closeModal(reloadTable: boolean = false) {
        this.tpeForm = this.tpeFormService.newForm();
        this.modalCloseEvent.emit(reloadTable);
    }

    /**
     * Parses the error payload for a message to display or defaults to a generic error message.
     * @param error The error payload from the API.
     * @returns The error message to display to the user.
     */
    private getErrorMessage(error: unknown): string {
        const defaultErrorMessage = 'An unexpected error occurred.';
        return (error as { message?: string }).message ?? defaultErrorMessage;
    }
}
