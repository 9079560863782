<div class="modal-header title-text">
    <ng-content />
</div>
@if (showCloseButton()) {
    <pay-modal-close-button
        [disabled]="closeDisabled()"
        [confirmMessage]="closeConfirmMessage()"
        [popupKey]="closePopupKey()"
        (closeClick)="closeClick.emit($event)"
    />
} @else {
    <div class="header-spacer"></div>
}
