<pay-create-tpe-modal #createTpeModal [visible]="modalOpen" (modalCloseEvent)="closeTpeModal($event)" />

<pay-table-view>
    <pay-table-view-title>
        <h3>Third Party Entities</h3>
    </pay-table-view-title>
    <pay-table-view-actions>
        <pay-table-search-input [table]="table" />
        <button pButton (click)="openTpeModal()">Create Third Party Entity</button>
    </pay-table-view-actions>
    <pay-table
        #table
        resizableColumns
        showCurrentPageReport
        [columnOrder]="visibleColumns()"
        [dataSource]="tableDataSource"
        [loading]="loading()"
    >
        <pay-table-text-column sortable name="display_name" headerText="Third Party Entity" />
        <pay-table-text-column name="identifier" headerText="Identifier" />
        <pay-table-datetime-column name="created_at" headerText="Created Date" [formatOptions]="createdDateFormat" />
    </pay-table>
</pay-table-view>
