@if (!isStaticPage(locationPath)) {
    <div>
        <p-toast position="top-center" key="center" />
        <p-toast position="top-right" key="main" />
        <!-- Confirm key should not be used for non confirmation messages, as it causes the user to be logged out -->
        <p-toast position="top-right" key="confirm" (onClose)="onReject()" [baseZIndex]="5000">
            <ng-template let-message pTemplate="message">
                <div class="flex flex-col" style="flex: 1">
                    <div class="text-center">
                        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                        <h4>{{ message.summary }}</h4>
                        <p>{{ message.detail }}</p>
                    </div>
                    <button
                        pButton
                        fluid
                        type="button"
                        (click)="onConfirm()"
                        [label]="message.data?.confirmText"
                        class="p-button-primary"
                    ></button>
                    <button
                        pButton
                        fluid
                        type="button"
                        [label]="message.data?.rejectText"
                        class="p-button-secondary mt-4"
                        (click)="onReject()"
                    ></button>
                </div>
            </ng-template>
        </p-toast>
    </div>
}

<router-outlet />
