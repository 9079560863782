import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { identifierRegex } from '@pi/pi-common/src/identifier-regex';
import type { TPEEntityType } from '@pi/pi-common/src/ui-dtos/third-party-entity.dtos';

import type { TpeForm } from './tpe-form.interface';
import type { DropdownOption } from '../../../lib/interfaces/dropdown-option.dtos';

@Injectable({
    providedIn: 'root'
})
export class TpeFormService {
    public newForm(): FormGroup<TpeForm> {
        return new FormGroup<TpeForm>({
            display_name: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
            identifier: new FormControl<string>('', {
                nonNullable: true,
                validators: [Validators.required, Validators.pattern(identifierRegex)]
            }),
            type: new FormControl<TPEEntityType>('THIRD_PARTY_ADMINISTRATOR', {
                nonNullable: true,
                validators: [Validators.required]
            })
        });
    }

    public getEntityTypeOptions(): DropdownOption<TPEEntityType>[] {
        return [
            {
                value: 'THIRD_PARTY_ADMINISTRATOR',
                label: 'Third Party Administrator'
            }
        ];
    }
}
