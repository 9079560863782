<p-dialog [modal]="true" [visible]="visible" [closable]="false" [style]="{ width: 'min(600px, 100%)' }">
    <ng-template pTemplate="header">
        <pay-modal-header (closeClick)="closeModal()" [showCloseButton]="true">
            Create Third Party Entity
        </pay-modal-header>
    </ng-template>
    <form>
        <pay-create-tpe-form [tpeForm]="tpeForm" [entityTypeOptions]="entityTypeOptions" />
    </form>
    <ng-template pTemplate="footer">
        <p-button
            label="Submit"
            styleClass="p-button-primary"
            [disabled]="(loading | async) === true || this.tpeForm.invalid"
            (onClick)="createThirdPartyEntity(tpeForm)"
        />
        <p-button
            label="Cancel"
            styleClass="p-button-secondary"
            [disabled]="(loading | async) === true"
            (onClick)="closeModal()"
        />
    </ng-template>
</p-dialog>
