{
    "name": "@pi/pi-plan-sponsor-ui",
    "private": true,
    "engines": {
        "node": "^20",
        "npm": "^10"
    },
    "scripts": {
        "ng": "ng",
        "start": "ng serve --configuration=localhost",
        "build": "ng build",
        "test": "node --max_old_space_size=4096 node_modules/@angular/cli/bin/ng test",
        "quiet-test": "node --max_old_space_size=4096 node_modules/@angular/cli/bin/ng test --silent",
        "e2e": "ng e2e",
        "quiet-lint": "eslint --quiet -c .eslintrc.json ./src",
        "lint": "eslint -c .eslintrc.json ./src",
        "fix-lint": "eslint --fix -c .eslintrc.json ./src",
        "analyze": "ng build --stats-json && webpack-bundle-analyzer dist/pi-plan-sponsor-ui/stats.json",
        "push-dev": "bash build-and-deploy.sh dev",
        "push-dev2": "bash build-and-deploy.sh dev2",
        "push-dev3": "bash build-and-deploy.sh dev3",
        "push-dev4": "bash build-and-deploy.sh dev4",
        "push-dev5": "bash build-and-deploy.sh dev5",
        "push-dev6": "bash build-and-deploy.sh dev6",
        "push-demo1": "bash build-and-deploy.sh demo1",
        "push-demo2": "bash build-and-deploy.sh demo2",
        "push-demo3": "bash build-and-deploy.sh demo3",
        "push-test1": "bash build-and-deploy.sh test1",
        "push-qa": "bash build-and-deploy.sh qa",
        "push-prod": "bash build-and-deploy.sh prod",
        "localhost": "ng serve --configuration=localhost",
        "dev": "ng serve --configuration=dev",
        "dev2": "ng serve --configuration=dev2",
        "dev3": "ng serve --configuration=dev3"
    },
    "dependencies": {
        "@angular/animations": "19.0.5",
        "@angular/cdk": "19.0.4",
        "@angular/common": "19.0.5",
        "@angular/compiler": "19.0.5",
        "@angular/core": "19.0.5",
        "@angular/forms": "19.0.5",
        "@angular/platform-browser": "19.0.5",
        "@angular/platform-browser-dynamic": "19.0.5",
        "@angular/router": "19.0.5",
        "@pi/pi-common": "file:../pi-common",
        "@primeng/themes": "19.0.2",
        "@sentry/angular": "8.48.0",
        "@stripe/stripe-js": "5.4.0",
        "ajv": "8.17.1",
        "angularx-qrcode": "19.0.0",
        "jwt-decode": "4.0.0",
        "lodash-es": "4.17.21",
        "luxon": "3.4.4",
        "memoizee": "0.4.17",
        "ngx-stripe": "19.0.0",
        "primeicons": "7.0.0",
        "primeng": "19.0.5",
        "rxjs": "7.8.1",
        "ts-md5": "1.3.1",
        "tslib": "2.6.3",
        "xlsx": "https://cdn.sheetjs.com/xlsx-0.20.3/xlsx-0.20.3.tgz",
        "zone.js": "0.15.0"
    },
    "devDependencies": {
        "@angular-builders/jest": "19.0.0",
        "@angular-eslint/builder": "19.0.2",
        "@angular-eslint/eslint-plugin": "19.0.2",
        "@angular-eslint/eslint-plugin-template": "19.0.2",
        "@angular-eslint/schematics": "19.0.2",
        "@angular-eslint/template-parser": "19.0.2",
        "@angular/build": "^19.0.6",
        "@angular/cli": "19.0.6",
        "@angular/compiler-cli": "19.0.5",
        "@angular/language-service": "19.0.5",
        "@happy-dom/jest-environment": "15.11.6",
        "@tailwindcss/typography": "0.5.16",
        "@types/jest": "29.5.12",
        "@types/lodash-es": "4.17.12",
        "@types/luxon": "3.4.2",
        "@types/memoizee": "0.4.11",
        "@types/parse5": "7.0.0",
        "@types/qrcode": "1.5.5",
        "@typescript-eslint/eslint-plugin": "7.18.0",
        "@typescript-eslint/parser": "7.18.0",
        "autoprefixer": "10.4.20",
        "eslint": "8.57.1",
        "eslint-config-prettier": "9.1.0",
        "eslint-plugin-import": "2.31.0",
        "eslint-plugin-unused-imports": "3.2.0",
        "jest": "29.7.0",
        "postcss": "8.4.49",
        "tailwindcss": "3.4.17",
        "tailwindcss-primeui": "0.3.4",
        "ts-node": "8.10.2",
        "typescript": "5.6.3",
        "webpack-bundle-analyzer": "4.10.2"
    }
}
