import { Component, input, output } from '@angular/core';

import { ModalCloseButtonComponent } from '../modal-close-button/modal-close-button.component';

@Component({
    selector: 'pay-modal-header',
    templateUrl: './modal-header.component.html',
    styleUrl: './modal-header.component.scss',
    imports: [ModalCloseButtonComponent]
})
export class ModalHeaderComponent {
    protected readonly showCloseButton = input(false);
    protected readonly closeDisabled = input(false);
    protected readonly closeConfirmMessage = input<string | undefined | null>();
    protected readonly closePopupKey = input<string | undefined | null>();
    protected readonly closeClick = output<'CLOSE' | 'CANCELED'>();
}
